import React from 'react'

const styles = {
    panel: {
        marginTop: '50px',
        marginBottom: '50px'
    },
}


const HorizontalCardPanel = ({children}) => {
    return (
        <div className="HorizontalCardPanel" style={styles.panel}>
            {children}
        </div>
    )
}

export default HorizontalCardPanel;